@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~@nebular/auth/styles/globals';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

tr td.ng2-smart-actions,
ng2-st-tbody-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.ng2-smart-action-delete-delete,
.ng2-smart-action-edit-edit,
.ng2-smart-action-custom-custom {
  padding-top: 0.5em;
}

.ng2-smart-action.ng2-smart-action-custom-custom:hover {
  color: #1e6bb8;
}




